import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import ConfigAPI from '@/config/api'
import Session from '@/handler/session'

Vue.use(VueAxios, axios)

const instance = axios.create();

const servicesAPI = {
  obtener() {
    var url = ConfigAPI.baseURL + "reports-payment" + Session.getToken();
    return instance.get(url);
  },
  mostrar(id) {
    var url = ConfigAPI.baseURL + "reports-payment/" + id + Session.getToken();
    return instance.get(url);
  },
  agregar(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "reports-payment" + Session.getToken();
    return instance.post(url,params);
  },
  editar(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminar(id) {
    var url = ConfigAPI.baseURL + "reports-payment/"+ id + Session.getToken();
    return instance.delete(url);
  }, 

  filtrarReport(data, page) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment/filter/query" + Session.getToken() + "&page=" + page;
    return instance.post(url,params);
  },  

  obtenerClientes() {
    var url = ConfigAPI.baseURL + "customers" + Session.getToken();
    return instance.get(url);
  },
  obtenerClientesByZonas(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "sellers/filter/customerByZone" + Session.getToken();
    return instance.post(url,params);     
  },
  obtenerStaff() {
    var url = ConfigAPI.baseURL + "staff" + Session.getToken();
    return instance.get(url);
  },  
  obtenerVendedores() {
    var url = ConfigAPI.baseURL + "sellers" + Session.getToken();
    return instance.get(url);
  },  
  
  getVouchersPendingByCustomers (data) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment/vouchers/filter/query/pending" + Session.getToken();
    return instance.post(url,params);
  },
  getBalancePendingByCustomers (data) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment/vouchers/filter/total/pending" + Session.getToken();
    return instance.post(url,params);
  },  

  obtenerReportsBySeller(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment/filter/query/seller" + Session.getToken();
    return instance.post(url,params);        
  },
  
  obtenerSurrender() {
    var url = ConfigAPI.baseURL + "reports-payment-surrender" + Session.getToken();
    return instance.get(url);
  },
  mostrarSurrender(id) {
    var url = ConfigAPI.baseURL + "reports-payment-surrender/" + id + Session.getToken();
    return instance.get(url);
  },
  agregarSurrender(data) {    
    var params = data
    var url = ConfigAPI.baseURL + "reports-payment-surrender" + Session.getToken();
    return instance.post(url,params);
  },
  editarSurrender(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment-surrender/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
  eliminarSurrender(id) {
    var url = ConfigAPI.baseURL + "reports-payment-surrender/"+ id + Session.getToken();
    return instance.delete(url);
  },

  filtrarReportSurrender(data) {
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment-surrender/filter/query" + Session.getToken();
    return instance.post(url,params);
  },    

  editarSurrenderDetail(data) {    
    var params = data    
    var url = ConfigAPI.baseURL + "reports-payment-surrender-detail/"+ data.id + Session.getToken();
    return instance.post(url,params);
  },
}

export default servicesAPI;
